import React from 'react';
import Slider from "react-slick";

const SixthSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const sixthSectionSlider = [
    {
      Title: 'Quem já usou',
      Text: 'Dolores, totam aspernatur non quisquam sint expedita temporibus! Harum eligendi beatae iure, cumque cum voluptatem?',
      Name: 'Raquel Barbosa',
      Type: 'Aniversariante',
    },
    {
      Title: 'Quem já usou',
      Text: 'Dolores, totam aspernatur non quisquam sint expedita temporibus! Harum eligendi beatae iure, cumque cum voluptatem?',
      Name: 'Raquel Barbosa',
      Type: 'Aniversariante',
    },
    {
      Title: 'Quem já usou',
      Text: 'Dolores, totam aspernatur non quisquam sint expedita temporibus! Harum eligendi beatae iure, cumque cum voluptatem?',
      Name: 'Raquel Barbosa',
      Type: 'Aniversariante',
    },
  ];
  return (
    <section className="sixth">
      <div className="slider">        
        <Slider {...settings}>
          {sixthSectionSlider.map((item, index) => (
            <div key={index.toString()} className="item">
              {/* <div className="quotes" style={{backgroundImage: `url(${quotesImg})`}}></div> */}
              <h1 className="title yellow-font bold text-center">{item.Title}</h1>
              <p className="description blue-font text-center">{item.Text}</p>
              <h1 className="title yellow-font bold text-center">{item.Name}</h1>
              <h1 className="title yellow-font regular text-center">{item.Type}</h1>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SixthSection;